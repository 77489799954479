// @flow strict
import React from 'react';
import PropTypes from 'prop-types';
import * as Immutable from 'immutable';
import { trim } from 'lodash';

import { DropdownButton, FormControl, FormGroup, InputGroup, MenuItem } from 'components/graylog';
import FormsUtils from 'util/FormsUtils';

import CustomPropTypes from 'views/components/CustomPropTypes';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import ParameterType from 'views/logic/parameters/Parameter';
import ParameterDeclarationForm from './ParameterDeclarationForm';

type Props = {
  parameter: ParameterType,
  binding?: string,
  onBlur: () => mixed,
  onChange: (string, any) => any,
  onDelete: (string) => any,
  onEdit: (string, ParameterType) => any,
};

type State = {
  binding: string,
};

export default class Parameter extends React.Component<Props, State> {
  declarationForm: any;

  static propTypes = {
    parameter: CustomPropTypes.instanceOf(ParameterType).isRequired,
    binding: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    binding: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = { binding: props.binding || '' };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { binding: nextBinding } = nextProps;
    const { binding: currentBinding } = this.state;
    if (currentBinding !== nextBinding) {
      this.setState({ binding: nextBinding });
    }
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    const { onChange } = this.props;
    const { name } = e.target;
    const value = FormsUtils.getValueFromInput(e.target);
    this.setState({ binding: value }, () => onChange(name, value));
  };

  handleEdit = () => {
    this.declarationForm.open();
  };

  handleDelete = () => {
    const { onDelete, parameter } = this.props;
    onDelete(parameter.name);
  };

  handleUpdate = (parameters: ParameterMap) => {
    const { parameter, onEdit } = this.props;
    const newParameter = parameters.get(parameter.name);

    onEdit(parameter.name, newParameter);
    this.declarationForm.close();
  };

  render() {
    const { parameter, onBlur } = this.props;
    const { binding } = this.state;
    const { name, title } = parameter;
    const validationState = !trim(binding) ? 'error' : null;

    const parameters = Immutable.fromJS({ [name]: parameter });

    return (
      <FormGroup key={name} controlId={`form-inline-${name}`} style={{ marginRight: 5 }} validationState={validationState}>
        <InputGroup>
          <DropdownButton componentClass={InputGroup.Button}
                          id={`parameter-dropdown-${name}`}
                          title={title}>
            <MenuItem key="edit" onSelect={this.handleEdit}>Edit</MenuItem>
            <MenuItem key="delete" onSelect={this.handleDelete}>Delete</MenuItem>
          </DropdownButton>

          <FormControl type="text"
                       name={name}
                       placeholder={title}
                       onBlur={onBlur}
                       onChange={this.handleChange}
                       value={binding || ''} />

          <ParameterDeclarationForm ref={(c) => { this.declarationForm = c; }}
                                    parameters={parameters}
                                    onSave={this.handleUpdate} />
        </InputGroup>
      </FormGroup>
    );
  }
}
